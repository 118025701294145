<template>
    <v-container>
        <div id="main_container">

            <div color="transparent" class="ml-4 mr-4">
                <v-row no-gutters align="center" >
                    <v-col>
                        <v-img  class="logo" max-width="150px" src="@/assets/wildix-logo-2.png" > </v-img>
                    </v-col>

                    <v-col  >
                        <v-toolbar-title class="text-center">Cloud PBX <b>{{pbxName}}</b> Status</v-toolbar-title>
                    </v-col>
                    <v-col  >
                        <v-btn class="subscribe" depressed outlined color="#003366"
                               :disabled="topSubscribeBtnDisabled"
                               @click="openDialogSubs()" >Subscribe</v-btn>
                    </v-col>
                </v-row>
            </div>

            <v-divider></v-divider>
            <v-alert
                    class="ma-4"
                    v-model="notWms5"
                    type="info"
            >
                Wildix PBX Status Page is only available from WMS 5. Please upgrade.
            </v-alert>
            <div class="ma-4">
                <div class="flex-space-between">
                    <h3>PBX Status: </h3>
                    <h3>SLI: {{statusSLI}}</h3>
                </div>
                <v-card
                        class="rounded white--text"
                        v-bind:color="statusColor"
                        outlined
                        tile>
                    <v-card-text style="font-size:24px" class="white--text text-center">{{status}}<v-icon dark right>{{statusIcon}}</v-icon></v-card-text>
                </v-card>
            </div>

            <v-divider></v-divider>

            <div  class="ma-4">
                <h3>Services status:</h3>
                <v-card class="ma-1 pa-2 rounded"
                        outlined
                        tile
                        color="#F6F8FA ">
                    <v-list-item one-line>
                        <v-card-text style="font-size:24px" class="text-left">
                            Telephony
                            <v-icon left>mdi-phone</v-icon></v-card-text>

                        <div v-bind:class="sipStatusClass">{{sipStatus}}</div>
                        <v-icon v-bind:class="sipStatusClass"  right>{{sipStatusIcon}}</v-icon>
                    </v-list-item>
                </v-card>
                <v-card class="ma-1  pa-2 rounded"
                        outlined
                        tile
                        color="#F6F8FA ">
                    <v-list-item one-line>
                        <v-card-text style="font-size:24px" class="text-left">
                            Collaboration
                            <v-icon left>mdi-chat</v-icon></v-card-text>
                        <div v-bind:class="xmppStatusClass">{{xmppStatus}}</div>
                        <v-icon v-bind:class="xmppStatusClass" right>{{xmppStatusIcon}}</v-icon>
                    </v-list-item>

                </v-card>
                <v-card class="ma-1  pa-2 rounded"
                        outlined
                        tile
                        color="#F6F8FA ">
                    <v-list-item one-line>
                        <v-card-text style="font-size:24px" class="text-left">
                            Web & API
                            <v-icon left>mdi-https</v-icon></v-card-text>
                        <div v-bind:class="httpStatusClass">{{httpStatus}}</div>
                        <v-icon v-bind:class="httpStatusClass" right>{{httpStatusIcon}}</v-icon>
                    </v-list-item>
                </v-card>
            </div>
            <transition name="fade">
            <div  v-if="showIncidentsFlag" id="hide" class="ma-4">
                <div>
                <h3>Past Incidents:</h3>
                        <v-card v-for="incident in incidents" :key="(incident.StartDate+incident.ComponentName)" class="ma-1  pa-2 rounded"
                                outlined
                                tile
                                color="#F6F8FA ">
                            <v-list-item one-line>
                                <v-card-title  style="font-size:24px" class="text-left; text-no-wrap">{{incident.ComponentName}}</v-card-title>
                                <v-spacer></v-spacer>
                                <v-card-text class="text-right">
                                    <small>{{incident.StartDate}} <br>Resolved:{{incident.EndDate}}</small>
                                </v-card-text>

                            </v-list-item>

                        </v-card>
                </div>
            </div>
            </transition>

            <v-row class="pb-4"><v-btn class="mx-auto" width="220px" @click="showIncidents()" :disabled="incidentsBtntDisabled" depressed outlined color="#003366">
                {{incidentsBtnText}}</v-btn></v-row>
        </div>
        <v-row justify="center">
            <v-dialog v-model="dialogSubs" persistent max-width="600px">
                <v-form
                        v-model="valid"
                >
                <v-card>
                    <v-card-title>
                        <span class="headline">Subscribe to receive status updates by email</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field label="Email" v-model="email" :rules="emailRules" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                        <h4>{{subscribeMsg}}</h4>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn depressed outlined color="#003366" @click="closeDialogSubs()">Close</v-btn>
                        <v-btn depressed outlined color="#003366" :disabled="!valid " @click="subscribe">{{subscribeBtnText}}</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-form>
            </v-dialog>
        </v-row>
        <!---->
        <v-container>
            <v-row
                    class="ma-4"
                    align="center"
                    justify="center">
                <router-link to="/">
                    <v-img max-width="150" src="@/assets/logo.svg"></v-img>
                </router-link>
            </v-row>
        </v-container>

    </v-container>


</template>

<script>
    import apiservice from "../common/api.service";

    export default {
        name: "PBX",
        data: () => ({
            notWms5:false,
            pbxName:"",
            pbxSerial:"",
            statusColor:"grey",
            sipStatusClass:"unknown",
            xmppStatusClass:"unknown",
            httpStatusClass:"unknown",
            statusIcon:"",
            sipStatusIcon:"mdi-checkbox-marked-circle",
            xmppStatusIcon:"mdi-cancel",
            httpStatusIcon:"mdi-wrench",
            status:"Unknown",
            statusDate:null,
            sipStatus:"Unknown",
            sipDate:null,
            xmppStatus:"Unknown",
            xmppDate:null,
            httpStatus:"Unknown",
            httpDate:null,
            init:false,
            timer:null,
            dialogSubs: false,
            valid: false,
            email: '',
            statusSLI:'',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            subscribeBtnText:"Subscribe",
            subscribeMsg:"",
            topSubscribeBtnDisabled:false,
            incidentsBtnText:"History of incidents",
            incidentsBtntDisabled:false,
            showIncidentsFlag:false,
            incidents:[],
        }),
        methods:{
            showIncidents(){
                //var main = this
                if (this.incidentsBtnText==="History of incidents"){
                    this.incidentsBtnText = "Loading"
                    this.fetchPbxIncidents()
                    this.showIncidentsFlag = true
                    this.incidentsBtnText = "Hide"
                }else{
                    this.showIncidentsFlag = false
                    this.incidentsBtnText = "History of incidents"
                }
            },
            openDialogSubs(){
                this.subscribeBtnText = "Subscribe"
                this.subscribeMsg=""
                this.dialogSubs=true
            },
            closeDialogSubs(){
                this.dialogSubs=false
                this.subscribeBtnText = "Subscribe"
                this.subscribeMsg=""
                this.email=""
            },
            subscribe(){
                let main = this
                let force = ""
                let action = "/subscribe"
                if (this.subscribeBtnText==="Re-send"){action="/resend"}
                if (this.subscribeBtnText==="Manage Subscriptions"){
                    action="/resend"
                    force="/force"
                }
                apiservice
                .get("pbx/"+this.pbxName+action,this.email+force)
                .then(resp => {
                    //console.log(resp)
                    if (resp.data === "Force sent") {
                        main.subscribeMsg="An email has been sent. Please check your inbox"
                        main.valid=false
                    }
                    if (resp.data === "New Subscription"||resp.data === "Sent") {
                        main.subscribeMsg="Look for the verification email in your inbox and click the link in that email"
                        main.valid=false
                    }
                    if (resp.data === "Subscribed") {
                        main.subscribeMsg="Subscribed successfully"
                        main.valid=false
                    }
                    if (resp.data === "Subscribed already") {
                        main.subscribeMsg="Subscriber already exists"
                        main.subscribeBtnText = "Manage Subscriptions"
                        main.valid=true
                    }
                    if (resp.data === "Resend") {
                        main.subscribeMsg="Look for the verification email in your inbox or press 'Re-send' button to send a new email"
                        main.subscribeBtnText = "Re-send"
                    }
                })
            },
            fetchPbxIncidents() {
                let main = this
                apiservice
                    .get("pbx", this.pbxName+"/incidents")
                    .then(response => {
                        main.incidents=response.data.Incidents
                        this.incidents.forEach(incident => {
                            incident.StartDate = new Date(incident.StartDate)
                            if (incident.EndDate!= null){
                              incident.EndDate = new Date(incident.EndDate)
                            } else {
                              incident.EndDate="no ETA at this moment"
                            }
                        })
                        this.incidents.sort(function (a,b) {
                            return b.StartDate-a.StartDate
                        })
                    })
                    .catch(function (e) {
                        console.log("catch")
                        if (!main.init) main.$router.push("/404")
                        console.log(e)
                    });
            },
            fetchPbxStatus() {
                let main = this
                apiservice
                    .get("pbx", this.pbxName)
                    .then(response => {
                        main.init=true
                        //console.log(response.data)
                        main.status = main.getStatus(response.data.Status)
                        main.topSubscribeBtnDisabled=false
                        main.incidentsBtntDisabled=false
                        if (main.status==="Disabled"){
                            main.incidentsBtntDisabled=true
                            main.topSubscribeBtnDisabled=true
                        }
                        main.statusDate = response.data.StatusDate
                        main.sipStatus=main.getStatus(response.data.StatusSip)
                        main.sipDate=response.data.StatusSipDate
                        main.xmppStatus=main.getStatus(response.data.StatusXmpp)
                        main.xmppDate=response.data.StatusXmppDate
                        main.httpStatus=main.getStatus(response.data.StatusHttp)
                        main.httpDate=response.data.StatusHttpDate
                        main.statusSLI=response.data.SLI
                        main.setStatusesClass()
                        if (response.data.WmsVersion<5){
                            main.incidentsBtntDisabled=true
                            main.topSubscribeBtnDisabled=true
                            main.cancelAutoUpdate()
                            main.notWms5=true
                        }
                    })
                    .catch(function (e) {
                        if (!main.init) main.$router.push("/404")
                        console.log(e)
                    });
            },
            setStatusesClass(){
                this.statusColor=this.getColorStatus(this.status)
                this.sipStatusClass=this.getClassStatus(this.sipStatus)
                this.xmppStatusClass=this.getClassStatus(this.xmppStatus)
                this.httpStatusClass=this.getClassStatus(this.httpStatus)
                this.statusIcon=this.getIconStatus(this.status)
                this.sipStatusIcon=this.getIconStatus(this.sipStatus)
                this.xmppStatusIcon=this.getIconStatus(this.xmppStatus)
                this.httpStatusIcon=this.getIconStatus(this.httpStatus)
            },
            getStatus(status){
                switch (status) {
                    case "OK":
                        return "Operational"
                    case "CRIT":
                        return  "Major outage"
                    case "WARN":
                        return  "Partial outage"
                    case "MAIN":
                        return "Maintenance"
                    case "DEAC":
                        return "Disabled"
                    default:
                        return "Unknown"
                }
            },
            getColorStatus(status){
                switch (status) {
                    case "Operational":
                        return "#003366"
                    case "Major outage":
                        return  "red"
                    case "Partial outage":
                        return  "orange"
                    case "Maintenance":
                        return "purple"
                    case "Disabled":
                        return "#f08080 "
                    //case "Degraded performance":
                    //return "degraded-performance"
                    default:
                        return "grey"
                }
            },
            getClassStatus(status){
                switch (status) {
                    case "Operational":
                        return "operational"
                    case "Major outage":
                        return  "major-outage"
                    case "Partial outage":
                        return  "partial-outage"
                    //case "Degraded performance":
                        //return "degraded-performance"
                    default:
                        return "unknown"
                }
            },
            getIconStatus(status){
                switch (status) {
                    case "Operational":
                        return "mdi-checkbox-marked-circle"
                    case "Major outage":
                        return  "mdi-cancel"
                    case "Partial outage":
                        return  "mdi-alert-circle"
                    case "Maintenance":
                        return "mdi-progress-wrench"
                    case "Disabled":
                        return "mdi-close-box-outline"
                    //case "Degraded performance":
                    //    return "mdi-progress-wrench"
                    default:
                        return "mdi-cloud-question"
                }
            },
            setAutoUpdate(){
                this.cancelAutoUpdate()
                this.timer = setInterval(this.fetchPbxStatus, 10000)
            },
            cancelAutoUpdate () {
                clearInterval(this.timer)
            },
        },
        created(){
            this.pbxName=this.$route.params.PBX
            document.title=this.pbxName + " Status Page"
        },
        mounted(){
            this.fetchPbxStatus()
            this.setAutoUpdate()
        },
        beforeDestroy () {
            this.cancelAutoUpdate()
        }
    }

</script>
<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.25s ease-out;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }



    .logo{
        float:left;
    }
    @media screen and (max-width: 640px) {
        .logo {
            float:none;
            display: block;
            margin: 0 auto;
        }
    }
    .subscribe{
        float:right;
    }

    @media screen and (max-width: 640px) {
        .subscribe {
            float:none;
            width: 100%;
        }
    }
    .rounded{
        border-radius:5px;
    }

    .unknown {
            color: grey;
    }
    .degraded-performance {
            color: purple;
        white-space: nowrap;
        }
    .partial-outage {
        white-space: nowrap;
            color: orange;
        }
    .major-outage {
        white-space: nowrap;
            color: red;
        }
    .operational {
        color: #003366;
    }
    .flex-space-between{
          display: flex;
          justify-content: space-between;
    }
</style>
